import styled from "@emotion/styled"
import { css, SerializedStyles } from "@emotion/react"

import { breakpoints } from "design-kit"
import { fonts } from "../../variables/typography/Fonts"

const statement1Styles = css`
  ${fonts.RightGrotesk}
  font-size: 56px;
  line-height: 56px;
  letter-spacing: 0.5px;

  ${breakpoints.desktop`
    font-size: 96px;
    line-height: 104px;
  `}
`
const statement2Styles = css`
  ${fonts.RightGrotesk}
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.5px;

  ${breakpoints.desktop`
    font-size: 80px;
    line-height: 88px;
    letter-spacing: 0.5px;
  `}
`
const statement3Styles = css`
  ${fonts.Roobert}
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.25px;

  ${breakpoints.desktop`
    font-size: 72px;
    line-height: 80px;
  `}
`
const statement4Styles = css`
  ${fonts.Roobert}
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.25px;

  ${breakpoints.desktop`
    font-size: 40px;
    line-height: 48px;
  `}
`

const headline1Styles = css`
  ${fonts.RightGrotesk}
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.25px;

  ${breakpoints.desktop`
    font-size: 48px;
    line-height: 52px;
  `}
`
const headline2Styles = css`
  ${fonts.RightGrotesk}
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.25px;

  ${breakpoints.desktop`
    font-size: 40px;
    line-height: 48px;
  `}
`
const headline3Styles = css`
  ${fonts.RightGrotesk}
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
`

const headlineStyles = css`
  ${fonts.Roobert}
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
`

const headlineBoldStyles = css`
  ${fonts.RoobertBold}
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
`

const headlineLargeStyles = css`
  ${fonts.Roobert}
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
`

const bodyStyles = css`
  ${fonts.Roobert}
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
`
const bodyBoldStyles = css`
  ${fonts.RoobertBold}
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
`
const bodySmallStyles = css`
  ${fonts.Roobert}
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`
const bodySmallBoldStyles = css`
  ${fonts.RoobertBold}
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`

const metaTextStyles = css`
  ${fonts.PitchSans}
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: uppercase;
`

type spacingOverride = "top" | "bottom" | "none" | "default"
const spaceOverride = (spacing?: spacingOverride): SerializedStyles => css`
  ${spacing === "bottom" || spacing === "none" ? `margin-top: 0;` : ""}
  ${spacing === "top" || spacing === "none" ? `margin-bottom: 0;` : ""}
`

// separating out the CSS like this allows us to use other
// html elements where required

export const Statement1 = styled.h1<{ spacing?: spacingOverride }>`
  ${statement1Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Statement2 = styled.h2<{ spacing?: spacingOverride }>`
  ${statement2Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Statement3 = styled.h3<{ spacing?: spacingOverride }>`
  ${statement3Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Statement4 = styled.h4<{ spacing?: spacingOverride }>`
  ${statement4Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Headline1 = styled.h1<{ spacing?: spacingOverride }>`
  ${headline1Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Headline2 = styled.h2<{ spacing?: spacingOverride }>`
  ${headline2Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Headline3 = styled.h3<{ spacing?: spacingOverride }>`
  ${headline3Styles}
  ${props => spaceOverride(props.spacing)}
`

export const Headline = styled.p<{ spacing?: spacingOverride }>`
  ${headlineStyles}
  ${props => spaceOverride(props.spacing)}
`

export const HeadlineBold = styled.p<{ spacing?: spacingOverride }>`
  ${headlineBoldStyles}
  ${props => spaceOverride(props.spacing)}
`

export const HeadlineLarge = styled.p<{ spacing?: spacingOverride }>`
  ${headlineLargeStyles}
  ${props => spaceOverride(props.spacing)}
`

export const Body = styled.p<{ spacing?: spacingOverride }>`
  ${bodyStyles}
  ${props => spaceOverride(props.spacing)}
`

export const BodyBold = styled.p<{ spacing?: spacingOverride }>`
  ${bodyBoldStyles}
  ${props => spaceOverride(props.spacing)}
`

export const BodySmall = styled.p<{ spacing?: spacingOverride }>`
  ${bodySmallStyles}
  ${props => spaceOverride(props.spacing)}
`

export const BodySmallBold = styled.p<{ spacing?: spacingOverride }>`
  ${bodySmallBoldStyles}
  ${props => spaceOverride(props.spacing)}
`

export const MetaText = styled.p<{ spacing?: spacingOverride }>`
  ${metaTextStyles}
  ${props => spaceOverride(props.spacing)}
`

// Sometimes we will want to use the styles with a different HTML element
// underneath - this allows that
export const typographyStyles = {
  statement1: statement1Styles,
  statement2: statement2Styles,
  statement3: statement3Styles,
  statement4: statement4Styles,
  headline1: headline1Styles,
  headline2: headline2Styles,
  headline3: headline3Styles,
  headline: headlineStyles,
  headlineBold: headlineBoldStyles,
  headlineLarge: headlineLargeStyles,
  body: bodyStyles,
  bodyBold: bodyBoldStyles,
  bodySmall: bodySmallStyles,
  bodySmallBold: bodySmallBoldStyles,
  metaText: metaTextStyles,
}
